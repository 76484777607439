import "./BackgroundOverlay.css";
import React, {ReactNode} from "react";

interface BackgroundProps {
    childComponent: ReactNode
}
function BackgroundOverlay({childComponent}: BackgroundProps){
    return (
        <div className="background-component">
            <div className="background-component-overlay">
                {childComponent}
            </div>
        </div>
    );
}

export default BackgroundOverlay;