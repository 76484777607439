import "./Dropdown.css";
import React from "react";
import { Link } from "react-router-dom";
import {MenuItem} from "../types/MenuItem";

interface DropdownProps {
  name: string;
  items: MenuItem[];
}
function Dropdown({ name, items }: DropdownProps) {
  return (
    <div className="dropdown-component">
      <h1 className="dropdown-button">{name}</h1>
      <div className="dropdown-content">
          {items.map(item=>(
              <Link to={item.path}>
                  <div>{item.name}</div>
              </Link>
          ))}
      </div>
    </div>
  );
}

export default Dropdown;
