import { Link } from "react-router-dom";
import "./Header.css";
import React from "react";
import Dropdown from "./Dropdown";
import {AppDropdownItemCollection} from "../data/AppDropdownItemCollection";
import {AboutDropdownItemCollection} from "../data/AboutDropdownItemCollection";

function Header() {
  return (
    <header className="header-component">
      <Link to="/">
        <h1 className="brand">burrow bytes</h1>
      </Link>
      <div className="menu">
          <Dropdown name="apps" items={AppDropdownItemCollection}></Dropdown>
          <div className="menu-spacer"></div>
          <Dropdown name="about" items={AboutDropdownItemCollection}></Dropdown>
      </div>
    </header>
  );
}

export default Header;
