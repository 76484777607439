import './App.css';
import Header from "./components/Header";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./components/Home";
import React from "react";
import {RouteConstants} from "./data/RouteConstants";
import ShoeHealth from "./components/Pages/ShoeHealth";
import Privacy from "./components/Pages/Privacy";
import Support from "./components/Pages/Support";
import BackgroundOverlay from "./components/BackgroundOverlay";

function App() {
  return (
    <Router>
      <div className="App">
        <Header></Header>
      </div>
        <Routes>
            <Route path={RouteConstants.ShoeHealth} element={<BackgroundOverlay childComponent={<ShoeHealth/>}/>}></Route>
            <Route path={RouteConstants.Privacy} element={<BackgroundOverlay childComponent={<Privacy/>}/>}></Route>
            <Route path={RouteConstants.Support} element={<BackgroundOverlay childComponent={<Support/>}/>}></Route>
            <Route path="/" element={<BackgroundOverlay childComponent={<Home/>}/>}></Route>
        </Routes>
    </Router>

  );
}

export default App;
