import "./Support.css";
import React from "react";
import {SupportStatementText} from "../../data/SupportStatementText";
import {Link} from "react-router-dom";

function Support() {
    return (
        <div className="support-component">
            <h1>Support</h1>
            <div className="support-component-statement">{SupportStatementText}</div>
            <Link to="mailto:support@burrowbytes.com">
                <h3 className="support-component-mail-link">support@burrowbytes.com</h3>
            </Link>
        </div>
    );
}

export default Support;