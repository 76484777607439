import "./Privacy.css"
import React from "react";
import {PrivacyPolicyText} from "../../data/PrivacyPolicyText";

function Privacy(){
    var statement = PrivacyPolicyText.replace("\n", "\n")
    return (
        <div className="privacy-component">
            <h1>Privacy Policy</h1>
            <div className="privacy-component-statement">{statement}</div>
        </div>
    );
}

export default Privacy;