import "./ShoeHealth.css";
import React from "react";
import AppStoreBadge from "../../images/appStore.svg";
import {Link} from "react-router-dom";

export const ShoeHealthAppStoreUrl = "https://apps.apple.com/us/app/shoe-health/id1671377783";
function ShoeHealth() {
  return (
    <div>
      <div>
        <h1>Shoe Health</h1>
        <p>Introducing the ultimate footwear manager.</p>
      </div>
      <div className="shoe-health-component-feature-grid">
        <div className="shoe-health-component-feature-item">
          <h3>Convenient</h3>
          <p>
            Keep using your favorite run tracking app! There's no need to track
            runs with Shoe Health. As long as your run app integrates with Apple
            Health, we'll pick up your activities.
          </p>
        </div>
        <div className="shoe-health-component-feature-item">
          <h3>Personal</h3>
          <p>
            Add one shoe, add all of your shoes! Any brand and any model! Give
            each one a unique nickname. When you're done with a pair of shoes,
            you can even archive them for posterity. Your endless shoe closet.
          </p>
        </div>
        <div className="shoe-health-component-feature-item">
          <h3>Private</h3>
          <p>
            There are no corporate interests involved here. Your data never
            leaves your device. There are no advertisements tracking your every
            move. It's just an app that I built for myself, and thought others
            might find useful.
          </p>
        </div>
      </div>
        <Link to={ShoeHealthAppStoreUrl}>
            <img src={AppStoreBadge} alt="Available in the App Store" />
        </Link>
    </div>
  );
}

export default ShoeHealth;
