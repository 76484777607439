import {AppMetadata} from "../types/AppMetadata";
import ShoeHealthIcon from "../images/shoeHealthLargeIconWhite.png";
import {RouteConstants} from "./RouteConstants";
import {ShoeHealthAppStoreUrl} from "../components/Pages/ShoeHealth";

export const AppMetadataCollection: AppMetadata[] = [
    {
        id: "shoeHealth",
        name: "Shoe Health",
        iconName: ShoeHealthIcon,
        shortDescription: "The ultimate footwear tracker.",
        appStoreUrl: ShoeHealthAppStoreUrl,
        pagePath: RouteConstants.ShoeHealth
    }
]