import "./FeaturedApp.css";
import React from "react";
import { AppMetadataCollection } from "../data/AppMetadataCollection";
import AppStoreBadge from "../images/appStore.svg";
import { Link } from "react-router-dom";

interface FeaturedProps {
  appId: string;
}
function FeaturedApp({ appId }: FeaturedProps) {
  const app = AppMetadataCollection.find((metadata) => metadata.id === appId);
  return (
    <div className="featured-app-component">
      {app ? (
        <div>
          <img src={app.iconName} alt={app.name} />
          <h1>{app.name}</h1>
          <p>{app.shortDescription}</p>
          <Link to={app.pagePath} className="learn-more">
            <b>Learn more</b>
          </Link>
          <br />
          <br />
          <Link to={app.appStoreUrl}>
            <img src={AppStoreBadge} alt="Available in the App Store" />
          </Link>
        </div>
      ) : (
        <h2>Unknown app</h2>
      )}
    </div>
  );
}

export default FeaturedApp;
