import {MenuItem} from "../types/MenuItem";
import {RouteConstants} from "./RouteConstants";

export const AboutDropdownItemCollection: MenuItem[] =[
    {
        name: "Support",
        path: RouteConstants.Support
    },
    {
        name: "Privacy",
        path: RouteConstants.Privacy
    }
]