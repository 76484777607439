import './Home.css';
import FeaturedApp from "./FeaturedApp";
import React from "react";

function Home() {
  return (
    <div className="home-component">
        <FeaturedApp appId="shoeHealth"></FeaturedApp>
    </div>
  );
}

export default Home;